import {Link} from 'gatsby';
import React, {useState} from 'react';
import portrait from '../assets/images/ruth-phil-georgia-portrait.jpg';

import signature from '../assets/images/signature.png';
import Layout from '../components/Layout';

const OurStory = () => {
  const [connectDropdownOpened, setConnectDropdownOpened] = useState(false);

  return (
    <Layout className="our-story">
      <article id="main">
        <img className="portrait" src={portrait} alt="portrait of Phil and Ruth" />
        <div className="body">
          <h1>Phil & Ruth<br />Rowntree</h1>
          <p>
            In a time of global uncertainty during the CoVid19 pandemic, Expectation Church lead pastors, Phil & Ruth
            Rowntree felt the undeniable call of God to establish a new church in Dublin. The vision was certain;
            to build an influential church that expects great things from God and attempts great things for God;
            so began the journey of Expectation Church.
            <br />
            <br />
            Phil & Ruth live in Dublin with their daughter Georgia. They are propelled by a love for Jesus and a deep
            desire for His life-transforming message to impact every heart and home in the city of Dublin, the nation
            of Ireland and the continent of Europe. Phil & Ruth are currently connecting with people who feel drawn to
            be a part of the astounding things God will do through Expectation Church.
            If that's you, or if you'd like to discover more, get in touch&nbsp;
            <div className={`dropdown ${connectDropdownOpened ? 'open' : ''}`} onClick={(e) => setConnectDropdownOpened((current) => !current)}>
              <span className="tip">here</span>
              <div className="dropdown-content">
                <Link to="/connect">FIND OUT MORE</Link>
                <Link to="/join-team">SERVE</Link>
              </div>
            </div>!
            <br />
            <br />
            Connect with Phil & Ruth on Instagram
            <br />
            <a href="https://instagram.com/philrowntree" target="_blank">@philrowntree</a>
            <br />
            <a href="https://instagram.com/ruthelizabethrowntree" target="_blank">@ruthelizabethrowntree</a>
          </p>
        </div>
      </article>
    </Layout>
  );
};

export default OurStory;
